import React from "react"
import $ from "jquery"
import presets from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import TreeTopo from "./treeTopo"
import TreeTopoMobile from "./treeTopoMobile"

var jsonObject = {
  "name": "192.168.9.2",
  "number": "",
  "type": "交换机",
  "subtype": "",
  // "toolip":"资源IP：网络设备IP地址 192.168.9.2<br>端口：默认 161<br>共同体：默认 public",
  "data": [{
    label: "资源IP",
    value: "网络设备IP地址 192.168.9.2"
  }, {
    label: "端口",
    value: "默认 161"
  }, {
    label: "共同体",
    value: "默认 public"
  }],
  "children": [{
    "name": "192.168.6.33",
    "number": "",
    "type": "服务器",
    "subtype": "linux",
    // "toolip":"资源IP：服务器IP地址 192.168.6.33<br>端口：默认 161<br>共同体：默认 public<br>操作系统：Linux",
    "data": [{
      label: "资源IP",
      value: "服务器IP地址 192.168.6.33"
    }, {
      label: "端口",
      value: "默认 161"
    }, {
      label: "共同体",
      value: "默认 public"
    }, {
      label: "操作系统",
      value: "Linux"
    }],
    "children": [{
      "name": "192.168.6.33",
      "number": "",
      "type": "应用",
      "subtype": "database",
      // "toolip":"数据库IP地址：192.168.6.33<br>数据库名：itoss<br>连接数：3<br>端口：3308<br>用户名：root<br>密码：root",
      "data": [{
        label: "数据库IP地址",
        value: "192.168.6.33"
      }, {
        label: "数据库名",
        value: "itoss"
      }, {
        label: "连接数",
        value: "3"
      }, {
        label: "端口",
        value: "3308"
      }, {
        label: "用户名",
        value: "root"
      }, {
        label: "密码",
        value: "root"
      }, {
        label: "数据库类型",
        value: "MySQL"
      }],
    }]
  }, {
    "name": "192.168.6.200",
    "number": "",
    "type": "服务器",
    "subtype": "linux",
    // "toolip":"资源IP：服务器IP地址 192.168.6.200<br>端口：默认 161<br>共同体：默认 public",
    "data": [{
      label: "资源IP",
      value: "服务器IP地址 192.168.6.200"
    }, {
      label: "端口",
      value: "默认 161"
    }, {
      label: "共同体",
      value: "默认 public"
    }, {
      label: "操作系统",
      value: "Windows"
    }],
    "children": []
  }]
};
var jsonObject2 = {
  "name": "192.168.9.2",
  "number": "",
  "type": "交换机",
  "subtype": "",
  // "toolip":"资源IP：网络设备IP地址 192.168.9.2<br>端口：默认 161<br>共同体：默认 public",
  "data": [{
    label: "资源IP",
    value: "192.168.9.2"
  }, {
    label: "端口",
    value: "默认 161"
  }, {
    label: "共同体",
    value: "默认 public"
  }],
  "children": [{
    "name": "192.168.6.33",
    "number": "",
    "type": "服务器",
    "subtype": "linux",
    // "toolip":"资源IP：服务器IP地址 192.168.6.33<br>端口：默认 161<br>共同体：默认 public<br>操作系统：Linux",
    "data": [{
      label: "资源IP",
      value: "192.168.6.33"
    }, {
      label: "端口",
      value: "默认 161"
    }, {
      label: "共同体",
      value: "默认 public"
    }, {
      label: "操作系统",
      value: "Linux"
    }],
    "children": [{
      "name": "192.168.6.33",
      "number": "",
      "type": "应用",
      "subtype": "database",
      // "toolip":"数据库IP地址：192.168.6.33<br>数据库名：itoss<br>连接数：3<br>端口：3308<br>用户名：root<br>密码：root",
      "data": [{
        label: "数据库IP地址",
        value: "192.168.6.33"
      }, {
        label: "数据库名",
        value: "itoss"
      }, {
        label: "连接数",
        value: "3"
      }, {
        label: "端口",
        value: "3308"
      }, {
        label: "用户名",
        value: "root"
      }, {
        label: "密码",
        value: "root"
      }, {
        label: "数据库类型",
        value: "MySQL"
      }],
    }]
  }, {
    "name": "192.168.6.200",
    "number": "",
    "type": "服务器",
    "subtype": "linux",
    // "toolip":"资源IP：服务器IP地址 192.168.6.200<br>端口：默认 161<br>共同体：默认 public",
    "data": [{
      label: "资源IP",
      value: "192.168.6.200"
    }, {
      label: "端口",
      value: "默认 161"
    }, {
      label: "共同体",
      value: "默认 public"
    }, {
      label: "操作系统",
      value: "Windows"
    }],
    "children": []
  }]
};
let _textX = 0, _textY = 0;
let links = [2, 42, 35]

class DrawTopo extends React.Component {

  componentDidMount() {
    this.initTopo()
    $(window).on("resize", this.resizeTopo);
  }

  componentWillUnmount() {
    $(window).off("resize", this.resizeTopo);
  }

  initTopo = () => {
    const _width = $(window).width();
    if (_width >= 750) {
      $('#dynamicTreeMobile').css('display', 'none');
      $('#dynamicTree').css('display', 'block');
      this.draw();
    } else {
      $('#dynamicTree').css('display', 'none');
      $('#dynamicTreeMobile').css('display', 'block');
      this.drawMobile();
      this.setMobileScale(_width);
    }
  }

  resizeTopo = () => {
    const _width = $(window).width();
    if (_width >= 750) {
      if ($('#dynamicTree').css('display') === 'none') {
        $('#dynamicTreeMobile').css('display', 'none');
        $('#dynamicTree').css('display', 'block');
        if (!$('#dynamicTree')[0].innerHTML) {
          setTimeout(() => {
            this.draw()
          }, 100)
        }
      }
    } else {
      if ($('#dynamicTreeMobile').css('display') === 'none') {
        $('#dynamicTree').css('display', 'none');
        $('#dynamicTreeMobile').css('display', 'block');
        if (!$('#dynamicTreeMobile')[0].innerHTML) {
          setTimeout(() => {
            this.drawMobile()
          }, 100)
        }
      }
      this.setMobileScale(_width)
    }
  }

  draw = () => {
    var option = {
    	dataUrl:jsonObject,
    	nodeHeight:35,
    	selector:"#dynamicTree"
    };
    var tree = new TreeTopo(option);
    tree.init()
    var nodes = $(option.selector + " svg g.node")
    // console.log(nodes);
    var types = []
    nodes.map((i, node) => {
      var data = node.__data__.data
      if (data && data.length) {
        data.map((item, j) => {
          this.createText(node, item, j)
        })
      }
      var _type = node.__data__.type;
      if (types.indexOf(_type) == -1) {
        this.createText2(node, node.__data__.type, i);
        types.push(_type);
      }
    });
  }

  drawMobile = () => {
    var option = {
    	dataUrl:jsonObject2,
    	nodeHeight:35,
    	selector:"#dynamicTreeMobile"
    };
    var tree = new TreeTopoMobile(option);
    tree.init()
    var nodes = $(option.selector + " svg g.node")
    // console.log(nodes);
    var types = []
    nodes.map((i, node) => {
      var data = node.__data__.data
      if (data && data.length) {
        data.map((item, j) => {
          this.createText(node, item, j)
        })
      }
      var _type = node.__data__.type;
      if (types.indexOf(_type) == -1) {
        this.createText2_mobile(node, node.__data__.type, i);
        types.push(_type);
      }
    });
  }

  createText = (node, item, i, width) => {
    var _text = document.createElementNS('http://www.w3.org/2000/svg', "text");
    _text.setAttribute("dx", width ? 42 + width : 42);
    _text.setAttribute("dy", i * 20 + 20);
    _text.setAttribute("style","text-anchor:start;" + `fill: ${width ? 'initial' : '#0a75c2;font-weight: bold'}`);
    _text.textContent = width ? item.value : item.label + "：";
    node.appendChild(_text);
    if (!width && i < 10) {
      this.createText(node, item, i, _text.getBBox().width);
    }
  }

  createText2 = (node, item, i) => {
    var svg = $("#dynamicTree svg g.bottom")[0]
    var _text = document.createElementNS('http://www.w3.org/2000/svg', "text");
    _text.setAttribute("dx", (i) * 300 + node.getBBox().width / 2);
    _text.setAttribute("dy", 410);
    _text.setAttribute("style","text-anchor:start;fill: #0a75c2;font-size: 24px;font-weight: bold;");
    _text.textContent = item;
    svg.appendChild(_text);
    var _box = _text.getBBox();
    _text.setAttribute("dx", _box.x - _box.width / 2);
  }

  createText2_mobile = (node, item, i) => {
    if (i === 0) {
      _textX = node.transform.baseVal[0].matrix.e + 25;
    }
    _textY += links[i] + node.getBBox().height + 20
    var svg = $("#dynamicTreeMobile svg g.bottom")[0]
    var _text = document.createElementNS('http://www.w3.org/2000/svg', "text");
    _text.setAttribute("dx", _textX);
    _text.setAttribute("dy", _textY);
    _text.setAttribute("style","text-anchor:start;fill: #0a75c2;font-size: 18px;font-weight: bold;");
    _text.textContent = item;
    svg.appendChild(_text);
    var _box = _text.getBBox();
    // _text.setAttribute("dx", _box.x - _box.width / 2);
  }

  setMobileScale = (_width) => {
    var svg = $("#dynamicTreeMobile svg")[0];
    if (svg) {
      var _g = $("#dynamicTreeMobile svg > g")[0];
      var svg_width = _width - 30, svg_scale = 1;
      if (_width >= 425) {
        svg_scale = 0.98;
      } else if (_width >= 395) {
        svg_scale = 0.9;
      } else if (_width >= 340) {
        svg_width = 340;
        svg_scale = 0.85;
      } else {
        svg_width = 320;
        svg_scale = 0.81;
      }
      svg.setAttribute("width", svg_width);
      _g.setAttribute("transform", "translate(5,0), scale(" + svg_scale + ")");
    }
  }

  render() {
    return (
      <div css={{
        marginLeft: 0,
        marginRight: 0,
        "@media (max-width: 340px)": {
          marginLeft: rhythm(-presets.gutters.default / 2),
          marginRight: rhythm(-presets.gutters.default / 2)
        }
      }}>
        <div id="dynamicTree" style={{ display: 'block' }}></div>
        <div id="dynamicTreeMobile" style={{ display: 'none' }}></div>
      </div>
    )
  }
}

export default DrawTopo
