import React from "react"
import SEO from "../../components/seo"
// import Card from "../../components/card"
// import Button from "../../components/button"
import Footer from "../../components/footer"
import Layout from "../../components/layout"
// import { vP } from "../../components/gutters"
import presets, { colors } from "../../utils/presets"
import typography, { rhythm, scale, options } from "../../utils/typography"
import { graphql, Link } from "gatsby";
import { itemListOnline } from "../../utils/sidebar/item-list"
import DrawTopo from "../../components/online/drawTopo"

const OnlinePage = ({ location, data }) => {
  const contentTitle = (
    <>
      <span>
          {data.markdownRemark.frontmatter.title}
      </span>
      <span css={{
        paddingLeft: 15,
        fontSize: '32px'
      }}>
        (<a href="http://itossdemo.weadmin.com" target="_blank" css={{
          textDecoration: 'underline',
          color: '#0a75c2',
          "&:hover": {
            textDecoration: 'underline',
            color: '#0a75c2',
            opacity: 0.8
          }
        }}>DEMO</a>)
      </span>
    </>
  )
  return (
      <Layout location={location} itemList={itemListOnline} contentTitle={contentTitle}>
          <SEO title={data.markdownRemark.frontmatter.title} />
          {/* <div css={{ ...styles.contentHeader, ...styles.sticky }}>
              <h2 css={styles.contentTitle}>
                  <span>
                      {data.markdownRemark.frontmatter.title}
                  </span>
                  <span css={{
                    paddingLeft: 15,
                    fontSize: '32px'
                  }}>
                    (<a href="http://itossdemo.weadmin.com" target="_blank" css={{
                      textDecoration: 'underline',
                      color: '#0a75c2',
                      "&:hover": {
                        textDecoration: 'underline',
                        color: '#0a75c2',
                        opacity: 0.8
                      }
                    }}>DEMO</a>)
                  </span>
              </h2>
          </div> */}
          <div css={styles.contact} style={{minHeight: 'calc(100vh - 392px)'}}>
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
            <DrawTopo />
            <ul>
              {itemListOnline.map((item, i) => {
                if (item.title !== '在线体验指南') {
                  return <li key={i}><Link to={item.link}>{item.title}</Link></li>
                }
              })}
            </ul>
          </div>
          {/* <div css={styles.footer}>
            <Footer />
          </div> */}
      </Layout>
  )
}

const styles = {
    cards: {
        borderRadius: presets.radiusLg,
        display: `flex`,
        flexWrap: `wrap`,
        width: `100%`,
    },
    cardHeadLine: {
        ...scale(2 / 5),
        lineHeight: 1.2,
        marginTop: 0,
        fontSize: 18
        // [presets.Tablet]: {
        //     fontSize: scale(1 / 10).fontSize,
        // },
        // [presets.Desktop]: {
        //     fontSize: scale(3 / 10).fontSize,
        // },
        // [presets.VHd]: {
        //     fontSize: scale(5 / 10).fontSize,
        // },
        // [presets.VVHd]: {
        //     fontSize: scale(7 / 10).fontSize,
        // },
    },
    contact: {
        flex: `0 0 100%`,
        maxWidth: `100%`,
        padding: rhythm(presets.gutters.default / 2),
        [presets.Hd]: { padding: 24 },
    },
    contentHeader: {
        alignItems: `center`,
        background: `rgba(255,255,255,0.98)`,
        borderBottom: `1px solid ${colors.ui.light}`,
        display: `flex`,
        flexDirection: `row`,
        height: presets.headerHeight,
        paddingLeft: `${rhythm(3 / 4)}`,
        paddingRight: `${rhythm(3 / 4)}`,
        zIndex: 99,
    },
    contentTitle: {
        ...scale(1),
        color: colors.skyDark,
        lineHeight: 1,
        margin: 0,
    },
    futuraParagraph: {
        fontFamily: options.headerFontFamily.join(`,`),
        fontSize: 14,
        marginBottom: 10,
    },
    footer: {
        background: `dimGray`,
        fontFamily: typography.options.headerFontFamily.join(`,`),
        paddingTop: 0,
        paddingBottom: 0,
    },
    sticky: {
      position: `sticky`,
      top: `calc(${presets.bannerHeight} - 1px)`,
      [presets.Desktop]: {
        top: `calc(${presets.headerHeight} + ${presets.bannerHeight} - 1px)`,
      },
    },
}

export default OnlinePage

export const pageQuery = graphql`
  query OnlinePageQuery {
    markdownRemark(
      frontmatter: { type: { eq: "online" } }
    ) {
      html
      frontmatter {
        title
      }
    }
  }
`
