import $ from "jquery"
import d3 from "d3"
import DefaultIcon from "../../images/online/Default.png"
import WindowsIcon from "../../images/online/Windows.png"
import LinuxIcon from "../../images/online/Linux.png"
import DatabaseIcon from "../../images/online/Database.png"

function Tree(option){
	var _fontSize = 10;
	var defaultOpt = {
		width:395,
		height:400,
		offsetTreeH:150,//影响树形横向的区域
		nodeHeight:20,//节点rect高度
		nodeWidth:{//传参为null就按字数设置宽度
			_0:180,
			_1:180,
			_2:205,
			_3:110,
			_4:160
		},//默认节点宽度
		selector:null,//挂载元素
		fontNum:1.2,//设置字体大小因子
		fontColors:{//字体颜色
			normal:'#fff',
			warning:'#e3e3e1',
			errors:'#f00'
		},
		bgColors:{
			normal:'#35ad5b',
			warning:'#e3e3e1',
			errors:'#f00'
		},
		dataUrl:'' //必填
	};

	option = $.extend(true, defaultOpt,option);

	this.width      = option.width;
	this.height     = option.height;
	this.nodeHeight = option.nodeHeight;
	this.nodeWidth  = option.nodeWidth;
	this.offsetTreeH = option.offsetTreeH;
	this.selector   = option.selector==null?"body":option.selector;
	this.fontNum    = option.fontNum;
	this.fontColors = option.fontColors;
	this.bgColors   = option.bgColors;
	this.dataUrl    = option.dataUrl;
	this._getFontSize = function(){
		return _fontSize;
	}

}

Tree.prototype.init = function(){
	var that = this;
	$(that.selector)[0].innerHTML = "";
	var tree = d3.layout.tree()
	// .size([that.width / 3, that.height])
  .size([that.width, 400])
	.separation(function(a,b){//间隔函数
		return (a.parent == b.parent ? 1: 2) / a.depth;
	});

  // var diagonal = d3.svg.diagonal.radial()
  //       .projection(function(d) { return [d.y, d.x / 180 * Math.PI]; });

  // console.log(that.selector);
	var svg = d3.select(that.selector).append("svg")
	    .attr("width", 340)
	    .attr("height", that.height)
      .append("g")
	    .attr("transform", "translate(5,0), scale(0.85)");

  // console.log(svg);
  // console.log(svg[0][0].getBBox());

  // var tooltip = d3.select("body")
  //   .append("div")
  //   .attr("id","nodeTooltip")
  //   .attr("class","tooltip")
  //   .style("opacity",0.0);


	if(Object.prototype.toString.call(that.dataUrl).toLowerCase()=='[object string]'){
		d3.json(that.dataUrl, function(error, root) {
			if (error) throw error;
			render(root);
		});
	}else{
		render(that.dataUrl);
	}

	function render(root){
	  var nodes = tree.nodes(root);
	  var links = tree.links(nodes);

		var linearGradient = svg.append("defs").append("linearGradient")
				.attr("id","linearColor2")
				.attr("x1","0%")
				.attr("y1","0%")
				.attr("x2","0%")
				.attr("y2","100%");
			linearGradient.append("stop")
				.attr("offset","0%")
				.style("stop-color", "#fff");
			linearGradient.append("stop")
				.attr("offset","100%")
				.style("stop-color", "#eee");
    // nodes.forEach(function(d) { d.y = d.depth * 180; });
	  var link = svg.selectAll(".link")
	      .data(links)
	      .enter()
	      .append("path")
	      .attr("class", "link")
	      .attr("d", function(d){
	      	var lineOffsetHeight;

	      	if(that.nodeHeight==null){//nodeWidth传参为null则按照字数来自动设置宽度
	      		lineOffsetHeight = d.source.data.length * 21
	      	}else{
	      		lineOffsetHeight = that.nodeHeight * d.source.data.length
	      	}
          // console.log(d.source.y);
	      	// lineOffsetHeight = lineOffsetHeight+15;
	      	// return "M"+d.source.x+" "+(d.source.y + +lineOffsetHeight)+" "+
      		// "L"+(d.source.x)+" "+(d.source.y+lineOffsetHeight)+
      		// " L"+(d.source.x)+" "+d.target.y+" L"+
      		// d.target.x+" "+d.target.y;
					return "M" + d.source.x + "," + d.source.y +
                    "V" + ((d.target.y - d.source.y) / 2 + d.source.y) +
                    "H" + d.target.x +
                    "V" + (d.target.y - that.nodeHeight * d.source.data.length / 2);
	      })
	      .attr("style",function(){
	      	return "stroke:#F7881F;fill: none;stroke-width: 1.5px;"
	      });

	  var node = svg.selectAll(".node")
	      .data(nodes)
	      .enter()
	      .append("g")
	      .attr("class", "node")
	      .attr("transform", function(d) {
					const _dy = d.y - that.nodeHeight * d.data.length / 2
	      	return "translate(" + (d.x-that.nodeWidth['_'+d.depth] / 2) + "," + (_dy < 0 ? 0 : _dy) + ")";
	      })
	      .attr("style",function(d){
	      	return "font: "+that._getFontSize()*that.fontNum+"px sans-serif;"
	      })
       //  .on("mouseover",function (d) {
       //    if (tooltip[0][0].style.opacity !== 1) {
       //      tooltip
       //          .html(d.toolip)
       //          .style("left",(d3.event.pageX - 20) +"px")
       //          .style("top",(d3.event.pageY +20)+"px")
       //          .style("opacity",1)
       //    }
       // })
       // .on("mouseout",function (d) {
       //     tooltip.style("opacity",0);
       // });;

	  node.append("rect")
	  	.attr("width",function(d){
	  		return that.nodeWidth==null?
	  		(d.name.length + d.number.length+2)*that._getFontSize()*that.fontNum:
	  		that.nodeWidth['_'+d.depth];
	  	})
		.attr("height", function(d) {
      // return that.nodeHeight;
      return 22 * d.data.length + 3;
    })
		.attr("x",0)
		.attr("y",0)
    // .attr("fill","#0a75c2")
    .attr("fill","url(#" + linearGradient.attr("id") + ")")
    .attr('stroke-width', 1)
    .attr('stroke', '#ddd')
    // .attr('rx', 4)
    // .attr('ry', 4)
		.attr("style",function(d){
			// if(d.type==="1"){
			// 	return "fill:"+that.bgColors.normal;
			// }else if(d.type==="2"){
			// 	return "fill:"+that.bgColors.warning;
			// }else{
			// 	return "fill:"+that.bgColors.errors;
			// }
		});

    node.append("svg:image")
  		.attr("x", 0)
  		.attr("y", -1)
  		.attr("width", 38)
  		.attr("height", 38)
      .attr("title", function(d) {
      })
  		.attr("xlink:href", function (d) {
        if (d.subtype === "linux") {
          return LinuxIcon
        } else if (d.subtype === "windows") {
          return WindowsIcon
        } else if (d.subtype === "database") {
          return DatabaseIcon
        } else {
          return DefaultIcon
        }
  		});

      var bottom = svg.append("g")
          .attr("class", "bottom")
    	    .attr("transform", "translate(0,0)");

      // node.append("text")
  	  //     .attr("dx", function(d) {
      //       console.log(this.getBBox());
  	  //     	return 130
  	  //     })
  	  //     .attr("dy", 230)
  	  //     .style("text-anchor", function(d) {
  	  //     	return "start";
  	  //     })
  	  //     .style("fill","#0a75c2")
      //     .style("font-size", 24)
      //     .style("font-weight", 'bold')
  	  //     .text(function(d) {
      //       console.log(d);
      //       return d.type;
      //     });


	  // node.append("text")
	  //     .attr("dx", function(d) {
	  //     	return that._getFontSize()*that.fontNum;
	  //     })
	  //     .attr("dy", (that._getFontSize()*that.fontNum+that.nodeHeight)/2-2)
    //     .attr("x", 30)
	  //     .style("text-anchor", function(d) {
	  //     	return "start";
	  //     })
	  //     // .style("fill","#fff")
	  //     .text(function(d) { return d.name; });
    //
	  //  node.append("text")
	  //     .attr("dx", function(d) {
	  //     	return that.nodeWidth==null?
	  //     	(d.name.length+2)*that._getFontSize()*that.fontNum:
	  //     	(that.nodeWidth['_'+d.depth]-3*that._getFontSize()*that.fontNum);
	  //     })
	  //     .attr("dy", (that._getFontSize()*that.fontNum+that.nodeHeight)/2-2)
	  //     .style("text-anchor", function(d) {
	  //     	return "start";
	  //     })
	  //     // .style("fill","#fff")
	  //     .text(function(d) { return d.number; });

	}};

// var jsonObject = {
// 			"name":"董事会",
// 			"number":"12",
// 			"type":"1",
// 			"children":
// 			[
// 			    {
// 			      "name":"职能部门" ,
// 			      "number":"3",
// 			      "type":"1",
// 			      "children":
// 			      [
// 			            {
// 			            "name":"o" ,
// 			            "number":"381",
// 			            "type":"1",
// 			            "children":
// 					        [
// 					            {
// 					            "name":"n",
// 					            "number":"180",
// 					            "type":"1",
// 					            "children":
// 							        [
// 							            {
// 							            "name":"m",
// 							            "number":"130",
// 							            "type":"1"
// 							            },
// 							            {
// 							            "name":"l",
// 							            "number":"29",
// 							            "type":"1"
// 							            },
// 							            {
// 							            "name":"k" ,
// 							            "number":"21",
// 							            "type":"1"
// 							            }
// 							        ]
// 					            },
// 					            {
// 					            "name":"j",
// 					            "number":"145",
// 					            "type":"1",
// 					            "children":
// 							        [
// 							            {
// 							            "name":"i",
// 							            "number":"60",
// 							            "type":"1"
// 							            },
// 							            {
// 							            "name":"h",
// 							            "number":"41",
// 							            "type":"1"
// 							            },
// 							            {
// 							            "name":"g" ,
// 							            "number":"44",
// 							            "type":"1"
// 							            }
// 							        ]
// 					            },
// 					            {
// 					            "name":"f" ,
// 					            "number":"56",
// 					            "type":"1",
// 					            "children":
// 							        [
// 							            {
// 							            "name":"e",
// 							            "number":"9",
// 							            "type":"1"
// 							            },
// 							            {
// 							            "name":"d",
// 							            "number":"2",
// 							            "type":"1"
// 							            },
// 							            {
// 							            "name":"c" ,
// 							            "number":"45",
// 							            "type":"1"
// 							            }
// 							        ]
// 					            }
// 					        ]
// 			            },
// 			            {
// 			            "name":"b" ,
// 			            "number":"121",
// 			            "type":"1"
// 			            }
// 			      ]
// 			    },
// 			    {
// 			      "name":"其他" ,
// 			      "number":"1",
// 			      "type":"1",
// 			      "children":
// 			      [
// 			            {
// 			            "name":"XX" ,
// 			            "number":"183",
// 			            "type":"1",
// 			            "children":[]
// 			            },
// 			            {
// 			            "name":"XXX" ,
// 			            "number":"8",
// 			            "type":"1"
// 			            }
// 			      ]
// 			    }
// 			]
// 		};
// var option = {
// 	dataUrl:jsonObject,
// 	nodeHeight:30,
// 	selector:"#dynamicTree"
// };
// var tree = new Tree(option);
// tree.init();

export default Tree
